<template>
  <h4>Tabellenstand - {{ games_count }} Spiele:</h4>

  <div class="table-responsive">
    <standings-table
      :standings="standings"
      :showName="true"
      :darkTable="true"
    ></standings-table>
  </div>

  <div class="mb-3">
    <standings-legend></standings-legend>
  </div>
</template>

<script>
import config from "/vue.config.js";
import axios from "axios";
import { mapMutations } from "vuex";
import StandingsTable from "./StandingsTable.vue";
import StandingsLegend from "./StandingsLegend.vue";

export default {
  components: { StandingsTable, StandingsLegend },
  props: ["seasonId", "games_count"],
  data() {
    return {
      standings: [],
    };
  },
  methods: {
    ...mapMutations(["startRequest", "finishRequest", "addToast"]),
  },
  mounted() {
    this.startRequest();
    axios
      .get(config.pluginOptions.apiBaseUrl + "/standings/" + this.seasonId)
      .then((response) => {
        if (response.data) {
          this.standings = response.data.results;
          this.finishRequest();
        }
      });
  },
};
</script>

<style lang="scss" scoped>
</style>