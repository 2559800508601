<template>
  <form ref="formElement" class="row g-3" @submit.prevent="submitSeason()">
    <div class="col-md-6">
      <label for="title" class="form-label">Saisontitel</label>
      <input
        type="text"
        class="form-control"
        id="title"
        placeholder="Offizieller Titel der Saison"
        v-model="season.title"
        required
      />
    </div>

    <div class="col-md-6">
      <label for="titleShort" class="form-label">Saisontitel - kurz</label>
      <input
        type="text"
        class="form-control"
        id="titleShort"
        placeholder="Kurztitel der Saison"
        v-model="season.title_short"
      />
    </div>

    <div class="col-12">
      <button type="submit" class="btn btn-primary">Absenden</button>
    </div>
  </form>
</template>

<script>
export default {
  props: ["defaultObject"],
  data() {
    return {
      season: {
        title: "",
        title_short: "",
      },
    };
  },
  computed: {
    parsedSeason() {
      return {
        title: this.season.title,
        title_short: this.season.title_short,
      };
    },
  },
  mounted() {
    this.setDefaultObject(this.defaultObject);
  },
  methods: {
    setDefaultObject(defaultSeason) {
      if (defaultSeason) {
        this.season.title = defaultSeason.title ? defaultSeason.title : "";
        this.season.title_short = defaultSeason.title_short ? defaultSeason.title_short : "";
      }
    },
    submitSeason() {
      this.$emit("submitForm", this.parsedSeason);
    },
    resetForm() {
      this.$refs.formElement.reset();
    }
  },
};
</script>