<template>
    <img v-if="image"
        :src="'data:image;base64,' + image"
        :width="imgWidth"
        :class="cssClass" />
    <font-awesome-icon
        v-else
        :icon="icon"
        :size="iconSize"
        :class="cssClass"
        ></font-awesome-icon>
</template>

<script>
export default {
    props: {
        large: {
            type: Boolean,
            default: false
        },
        icon: String,
        image: String,
        cssClass: String
    },
    computed: {
        imgWidth() {
            return this.large ? '' : '48';
        },
        iconSize() {
            return this.large ? '10x' : '3x'
        }
    }
}
</script>