<template>
  <div class="mb-4 d-flex justify-content-between align-items-center">
    <h4>Spiele:</h4>
    <create-modal
        icon="plus"
        modalTitle="Neues Spiel anlegen"
        formComponent="game-form"
        apiActionUrl="/games"
        successMessage="Das Spiel wurde erfolgreich erstellt!"
        :reloadOnSuccess="true"
        :parameters="{season: this.seasonId, players: this.players, nextNumber: this.nextNumber}"
      >
      </create-modal>
  </div>
  <ul>
    <li v-for="game in games" :key="game.id">
      <game-item :game="game" :logos="logos"></game-item>
    </li>
  </ul>
</template>

<script>
import config from "/vue.config.js";
import axios from "axios";
import { mapMutations } from "vuex";

import GameItem from "./GameItem.vue";
import CreateModal from "../ui/modals/CreateModal.vue";

export default {
  components: {
    GameItem,
    CreateModal
  },
  props: ["seasonId"],
  data() {
    return {
      games: [],
      logos: [],
      players: [],
      nextNumber: 0
    };
  },
  methods: {
    ...mapMutations(["startRequest", "finishRequest", "addToast"]),
    loadGames() {
      axios
      .get(config.pluginOptions.apiBaseUrl + "/games/" + this.seasonId)
      .then((response) => {
        if (response.data) {
          this.games = response.data.results;

          this.games.forEach(item => {
            if(parseInt(item.number) >= this.nextNumber) {
              this.nextNumber = parseInt(item.number) + 1;
            }
          });

          this.finishRequest();
        }
      });
    },
    loadLogos() {
      axios
        .get(config.pluginOptions.apiBaseUrl + "/logos/" + this.seasonId)
        .then((response) => {
          if (response.data) {
            this.logos = response.data.results;
          }
        });
    },
    loadPlayers() {
      axios
        .get(config.pluginOptions.apiBaseUrl + "/players")
        .then((response) => {
          if (response.data) {
            this.players = response.data.results;
          }
        });
    }
  },
  mounted() {
    this.startRequest();
    this.loadGames();
    this.loadLogos();
    this.loadPlayers();
  },
};
</script>

<style lang="scss" scoped>
ul {
  list-style-type: none;
  padding: 0;

  li {
    background-color: $lightgrey;
    padding: 1rem;
    margin: 1rem 0;
  }
}
</style>