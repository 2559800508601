<template>
  <div class="mb-4 d-flex justify-content-between align-items-center">
    <h1>
      <span v-if="player.player_name">{{ player.player_name }}</span>
      <span v-if="player.player_name && player.club_name"> - </span>
      <span v-if="player.club_name">{{ player.club_name }}</span>
    </h1>
    <div>
      <edit-modal
        icon="user-edit"
        modalTitle="Spieler bearbeiten"
        formComponent="player-form"
        :apiActionUrl="'/players/' + player.id"
        successMessage="Der Spieler wurde erfolgreich aktualisiert!"
        :defaultObject="player"
        @updateSuccess="loadPlayer"
      >
      </edit-modal>
      <delete-modal
        icon="user-times"
        modalTitle="Spieler löschen?"
        :apiActionUrl="'/players/' + player.id"
        successMessage="Der Spieler wurde erfolgreich gelöscht!"
        redirect="/players"
      >
        <p>
          Soll der Spieler
          <strong>
            <span v-if="player.player_name">{{ player.player_name }}</span>
            <span v-if="player.player_name && player.club_name"> - </span>
            <span v-if="player.club_name">{{ player.club_name }}</span>
          </strong>
          wirklich gelöscht werden?
        </p>
        <p>
          <strong>Achtung!</strong> Alle Spiele des Spielers werden ebenfalls
          gelöscht. Das kann Auswirkungen auf Tabellenstände haben!
        </p>
      </delete-modal>
    </div>
  </div>

  <div class="row">
    <div class="col-md-4 mb-5">
      <div class="text-center my-5">
        <div class="mb-5 mx-5">
          <icon-fallback
            icon="shield-alt"
            :large="true"
            :image="player.logo_image"
          />
        </div>

        <div class="mx-5">
          <icon-fallback
            icon="user"
            :large="true"
            :image="player.player_image"
          />
        </div>
      </div>
      <div>
        {{ player.games_played }} Spiele
        <span v-if="player.first_game && player.last_game">
          von
          {{ formatDate(player.first_game) }} bis
          {{ formatDate(player.last_game) }} </span
        ><sup>*</sup>
      </div>
      <div class="mt-4 d-none d-md-block">
        <standings-legend :showAdditional="true"></standings-legend>
      </div>
    </div>
    <div class="col-md-8">
      <div v-if="player.description" class="mb-5">
        <h4>Beschreibung:</h4>
        {{ player.description }}
      </div>
      <div v-if="totalStats" class="player-stats mb-5">
        <h4>Gesamte Karrierestatistik:</h4>
        <standings-table
          :standings="[totalStats]"
          :showName="false"
          :darkTable="true"
        ></standings-table>
      </div>

      <div
        v-for="stats in seasonStats"
        :key="stats.season.id"
        class="player-stats mb-5"
      >
        <div
          class="
            d-flex
            justify-content-between
            align-items-lg-center
            flex-column flex-md-row
            mb-2
          "
        >
          <h4 class="m-0">
            {{ stats.season.title
            }}<span v-if="stats.season.title_short">
              - {{ stats.season.title_short }}</span
            >:
          </h4>
          <div>Akt. Position: {{ stats.standings.rank }}.</div>
        </div>
        <standings-table
          :standings="[stats.standings]"
          :showName="false"
          :darkTable="true"
        ></standings-table>
      </div>

      <div class="mt-4 d-md-none">
        <standings-legend :showAdditional="true"></standings-legend>
      </div>
    </div>
  </div>
</template>

<script>
import config from "/vue.config.js";
import axios from "axios";
import { mapMutations } from "vuex";
import moment from "moment";
import StandingsTable from "../standings/StandingsTable.vue";
import StandingsLegend from "../standings/StandingsLegend.vue";

import DeleteModal from "../ui/modals/DeleteModal.vue";
import EditModal from "../ui/modals/EditModal.vue";

export default {
  components: {
    StandingsTable,
    StandingsLegend,
    DeleteModal,
    EditModal,
  },
  props: ["playerId"],
  data() {
    return {
      player: [],
      totalStats: [],
      seasonStats: [],
      deleteModal: null,
    };
  },
  methods: {
    ...mapMutations(["startRequest", "finishRequest", "addToast"]),
    formatDate(date) {
      return moment(String(date), "YYYY-MM-DD").format("D.M.YYYY");
    },
    loadPlayer() {
      this.startRequest();
      axios
        .get(config.pluginOptions.apiBaseUrl + "/players/" + this.playerId)
        .then((response) => {
          if (response.data) {
            this.player = response.data.results;
            this.finishRequest();
          }
        });
    },
  },
  mounted() {
    this.loadPlayer();

    this.startRequest();
    axios
      .get(
        config.pluginOptions.apiBaseUrl + "/standings/player/" + this.playerId
      )
      .then((response) => {
        if (response.data) {
          this.totalStats = response.data.results.total;
          this.seasonStats = response.data.results.seasons;

          this.finishRequest();
        }
      });
  },
};
</script>

<style lang="scss" scoped>
</style>