<template>
  <div><sup>1)</sup> G = Gespielte Spiele</div>
  <div><sup>2)</sup> S = Siege</div>
  <div><sup>3)</sup> N = Niederlagen</div>
  <div><sup>4)</sup> P = Punkte möglich</div>
  <div><sup>5)</sup> PE = Punkte erzielt</div>
  <div><sup>6)</sup> PZ = Punkte zugelassen</div>
  <div v-if="showAdditional"><sup>*</sup> inkl. Freundschaftsspiele</div>
</template>

<script>
export default {
  props: ["showAdditional"],
};
</script>