<template>
  <div class="mb-4 d-flex justify-content-between align-items-center">
    <h3>Spieler:</h3>

    <create-modal
      icon="user-plus"
      modalTitle="Neuen Spieler anlegen"
      formComponent="player-form"
      apiActionUrl="/players"
      successMessage="Der Spieler wurde erfolgreich erstellt!"
      @createSuccess="loadPlayers"
    >
    </create-modal>
  </div>

  <div v-if="players" class="list-group list-group-flush player-list">
    <router-link
      v-for="player in players"
      :key="player.id"
      class="
        list-group-item list-group-item-action
        d-md-flex
        align-items-center
        justify-content-between
      "
      :to="'/players/' + player.id"
    >
      <div class="d-flex align-items-center">
        <div class="img-wrapper-small player-logo">
          <icon-fallback
            icon="shield-alt"
            cssClass="text-dark"
            :image="logos[player.id]"
          />
        </div>

        <div class="font-weight-bold">
          <span v-if="player.player_name">{{ player.player_name }}</span>
          <span v-if="player.player_name && player.club_name"> - </span>
          <span v-if="player.club_name">{{ player.club_name }}</span>
        </div>
      </div>
      <div class="text-end">
        {{ player.games_played }} Spiele
        <span v-if="player.first_game && player.last_game">
          von
          {{ formatDate(player.first_game) }} bis
          {{ formatDate(player.last_game) }}
        </span>
      </div>
    </router-link>
  </div>
</template>

<script>
import config from "/vue.config.js";
import axios from "axios";
import { mapMutations } from "vuex";
import moment from "moment";

import CreateModal from "../ui/modals/CreateModal.vue";

export default {
  components: {
    CreateModal,
  },
  data() {
    return {
      players: [],
      logos: [],
    };
  },
  methods: {
    ...mapMutations(["startRequest", "finishRequest"]),
    formatDate(date) {
      return moment(String(date), "YYYY-MM-DD").format("D.M.YYYY");
    },
    loadPlayers() {
      this.startRequest();
      axios
        .get(config.pluginOptions.apiBaseUrl + "/players")
        .then((response) => {
          if (response.data) {
            this.players = response.data.results;
            this.finishRequest();
          }
        });
    },
  },
  mounted() {
    this.loadPlayers();

    axios.get(config.pluginOptions.apiBaseUrl + "/logos").then((response) => {
      if (response.data) {
        this.logos = response.data.results;
      }
    });
  },
};
</script>

<style lang="scss">
.player-list {
  .player-logo {
    margin-right: 1rem;
  }
}
</style>