<template>
  <div :class="{ 'position-relative': true, 'game-exclude': game.exclude_from_standings }">
    
    <div class="position-absolute">
      Nr. {{ game.number }}
    </div>
    
    <div class="text-center font-weight-bold mb-2">
      {{ game.title }}

      <edit-modal
        icon="edit"
        :id="'editModal_game' + game.id"
        modalTitle="Spiel bearbeiten"
        formComponent="game-form"
        :apiActionUrl="'/games/' + game.id"
        successMessage="Das Spiel wurde erfolgreich aktualisiert!"
        :defaultObject="game"
        :reloadOnSuccess="true"
      ></edit-modal>

      <delete-modal
        icon="trash-alt"
        :id="'deleteModal_game' + game.id"
        modalTitle="Spiel löschen?"
        :apiActionUrl="'/games/' + game.id"
        :reloadOnSuccess="true"
        successMessage="Das Spiel wurde erfolgreich gelöscht!"
      >
        <p>
          Soll das Spiel
          <strong>
            <span v-if="game.title">{{ game.title }}</span>
          </strong>
          wirklich gelöscht werden?
        </p>
        <p>
          <strong>Achtung!</strong> Die Daten können im Anschluss nicht wiederhergestellt werden.
        </p>
      </delete-modal>
    </div>

    <div class="d-flex justify-content-center align-items-center game-item">
      <div class="d-flex flex-column player-wrapper">
        <game-player v-if="game.player_0" :player="game.player_0" playerId="player-0" :logos="logos" logoPosition="right"/>
        <game-player v-if="game.player_0_b.id" :player="game.player_0_b" playerId="player-0b" :logos="logos" logoPosition="right"/>
      </div>

      <div class="mx-sm-3">
        <div class="text-center" v-if="game.date">
          {{ formattedDate }}
        </div>
        <div class="score d-flex">
          <div class="points">{{ game.points_0 }}</div>
          -
          <div class="points">{{ game.points_1 }}</div>
        </div>
      </div>

      <div class="d-flex flex-column player-wrapper">
        <game-player :player="game.player_1" playerId="player-1" :logos="logos" logoPosition="left"/>
        <game-player v-if="game.player_1_b.id" :player="game.player_1_b" playerId="player-1b" :logos="logos" logoPosition="left"/>
      </div>
    </div>

    <div class="text-center mt-1" v-if="game.note">
      {{ game.note }}
    </div>
  </div>
</template>

<script>
import moment from "moment";
import EditModal from "../ui/modals/EditModal.vue";
import DeleteModal from "../ui/modals/DeleteModal.vue";
import GamePlayer from "@/components/games/GamePlayer";

export default {
  components: {
    GamePlayer,
    EditModal,
    DeleteModal
  },
  props: ["game", "logos"],
  computed: {
    formattedDate() {
      return moment(String(this.game.date), "YYYY-MM-DD").format("D.M.YYYY");
    },
  },
};
</script>

<style lang="scss" scoped>
.game-exclude {
  opacity: 0.5;
}
.game-item {
  font-family: "Urbanist";

  .score {
    font-weight: 900;
    font-size: 2.5rem;
    line-height: 2.5rem;

    @include media-breakpoint-down(sm) {
      font-size: 2rem;
      line-height: 2rem;
    }

    div.points {
      width: 3.5rem;
      text-align: center;

      @include media-breakpoint-down(sm) {
        width: 2.5rem;
      }
    }
  }

  .player-wrapper {
    flex: 1;
  }
}
</style>
