<template>
  <a href="#" @click.prevent="showDeleteModal()" class="text-dark action-link">
    <font-awesome-icon :icon="icon"></font-awesome-icon>
  </a>

  <teleport to=".body-wrapper">
    <div
      class="modal fade"
      :id="id ? id : 'deleteModal'"
      tabindex="-1"
      aria-labelledby="deleteModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="deleteModalLabel">{{ modalTitle }}</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <slot></slot>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Abbrechen
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="deletePlayer()"
            >
              Löschen
            </button>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import config from "/vue.config.js";
import axios from "axios";
import { mapMutations } from "vuex";
import { Modal } from "bootstrap";

export default {
  props: [
    "id",
    "icon",
    "modalTitle",
    "apiActionUrl",
    "successMessage",
    "redirect",
    "reloadOnSuccess"
  ],
  emits: ["deleteSuccess"],
  data() {
    return {
      deleteModal: null,
    };
  },
  methods: {
    ...mapMutations(["startRequest", "finishRequest", "addToast"]),
    showDeleteModal() {
      this.deleteModal = new Modal(document.getElementById(this.id ? this.id : 'deleteModal'));
      this.deleteModal.show();
    },
    deletePlayer() {
      this.deleteModal.hide();
      this.startRequest();
      axios
        .delete(config.pluginOptions.apiBaseUrl + this.apiActionUrl)
        .then((response) => {
          if (response.status === 200) {
            this.addToast({
              message: this.successMessage,
            });
            
            if(this.reloadOnSuccess) {
              this.$router.go();
            } else if(this.redirect) {
              this.$router.push(this.redirect);
            } else {
              this.$emit('deleteSuccess');
            }
            this.finishRequest();
          }
        })
        .catch((error) => {
          this.addToast({ message: error.message, error: true });
          this.finishRequest();
        });
    },
  },
};
</script>