import { createApp } from 'vue';
import { createStore } from 'vuex';
import App from './App.vue';
import { createRouter, createWebHistory } from 'vue-router';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faShieldAlt, faVolleyballBall, faTrashAlt, faUser, faUserTimes, faUserPlus, faUserEdit, faEdit, faPlus, faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import SeasonList from './components/seasons/SeasonList.vue';
import SeasonDetails from './components/seasons/SeasonDetails.vue';
import PlayerList from './components/players/PlayerList.vue';
import PlayerDetails from './components/players/PlayerDetails.vue';
import IconFallback from './components/ui/IconFallback.vue';

import PlayerForm from './components/forms/PlayerForm';
import SeasonForm from './components/forms/SeasonForm';
import GameForm from './components/forms/GameForm';

import "./custom.scss";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";
import './registerServiceWorker'

const app = createApp(App);

library.add(faShieldAlt, faVolleyballBall, faTrashAlt, faUser, faUserTimes, faUserPlus, faUserEdit, faEdit, faPlus, faAngleUp, faAngleDown);

app.component('font-awesome-icon', FontAwesomeIcon);
app.component('icon-fallback', IconFallback);
app.component('player-form', PlayerForm);
app.component('season-form', SeasonForm);
app.component('game-form', GameForm);

// ROUTER:
const router = createRouter({
    history: createWebHistory(),
    routes: [{
            path: '/',
            component: SeasonList
        },
        {
            path: '/seasons/:seasonId',
            component: SeasonDetails,
            props: true
        },
        {
            path: '/players',
            component: PlayerList,
        },
        {
            path: '/players/:playerId',
            component: PlayerDetails,
            props: true
        },
    ],
    linkActiveClass: "active",
    linkExactActiveClass: "exact-active"
});
app.use(router);

// VUEX Store:
const store = createStore({
    state() {
        return {
            toasts: [],
            activeRequests: 0
        }
    },
    mutations: {
        addToast(state, message) {
            state.toasts.push(message);
        },
        startRequest(state) {
            state.activeRequests++;
        },
        finishRequest(state) {
            state.activeRequests--;
        }
    }
});
app.use(store);

app.mount('#app');