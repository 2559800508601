<template>
  <div class="mb-4 d-flex justify-content-between align-items-center">
    <h3>Saisons:</h3>
    <create-modal
      icon="plus"
      modalTitle="Neue Saison anlegen"
      formComponent="season-form"
      apiActionUrl="/seasons"
      successMessage="Die Saison wurde erfolgreich erstellt!"
      @createSuccess="loadSeasons"
    >
    </create-modal>
  </div>

  <div v-if="seasons" class="list-group list-group-flush">
    
    
    <router-link
      v-for="(season, index) in seasons"
      :key="season.id"
      class="
        list-group-item list-group-item-action
        d-md-flex
      "
      :to="'/seasons/' + season.id"
    >
      <div class="font-weight-bold me-auto">
        <span>{{ season.title }}</span>
        <span v-if="season.title_short"> - {{ season.title_short }}</span>
      </div>

      <div class="text-end season-stats ms-3 d-flex d-lg-block flex-column align-items-end">
        <span class="mr-5">{{ season.games_count }} Spiele</span>
        <span>{{ season.player_count }} Spieler</span> 
        <span v-if="season.min_date && season.max_date">
          &shy;{{ formatDate(season.min_date) }} -
          {{ formatDate(season.max_date) }}
        </span>

    <span class="sort-buttons d-inline-flex justify-content-end">
      <button v-if="index > 0" class="button-sort rounded-2 d-flex justify-content-center align-items-center" @click.prevent="sortSeason(season, 'up')"><font-awesome-icon icon="angle-up" /></button>
      <button v-if="index < seasons.length-1" class="button-sort rounded-2 d-flex justify-content-center align-items-center" @click.prevent="sortSeason(season, 'down')"><font-awesome-icon icon="angle-down" /></button>
    </span>
      </div>
    </router-link>

  </div>
</template>

<script>
import config from "/vue.config.js";
import axios from "axios";
import { mapMutations } from "vuex";
import moment from "moment";

import CreateModal from "../ui/modals/CreateModal.vue";

export default {
  components: {
    CreateModal,
  },
  data() {
    return {
      seasons: [],
    };
  },
  methods: {
    ...mapMutations(["startRequest", "finishRequest", "addToast"]),
    formatDate(date) {
      return moment(String(date), "YYYY-MM-DD").format("D.M.YYYY");
    },
    loadSeasons() {
      this.startRequest();
      axios
        .get(config.pluginOptions.apiBaseUrl + "/seasons")
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.seasons = response.data.results;
            this.finishRequest();
          }
        });
    },
    sortSeason(season, direction) {
      this.startRequest();
      axios
        .put(config.pluginOptions.apiBaseUrl + "/seasons/sort-" + direction + "/" + season.id)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.finishRequest();
            this.loadSeasons();
          }
        });
    }
  },
  mounted() {
    this.loadSeasons();
  },
};
</script>

<style lang="scss" scoped>
.season-stats {
  span {
    white-space: nowrap;
    &:not(:last-child) {
      @include media-breakpoint-up(lg) {
        margin-right: 1.5rem;
      }
    }
    @include media-breakpoint-down(sm) {
      &:nth-last-child(2) {
        margin-right: 0;
      }
      &:last-child {
        display: block;
      }
    }
  }
}

.sort-buttons {
    width: 50px;

    button.button-sort {
        appearance: none;
        padding: 0;
        border: none;
        background: none;
        height: 25px;
        width: 25px;
        &:hover {
            color: $white;
            background-color: $dark;
        }
    }
}
</style>