<template>
  <a href="#" @click.prevent="showEditModal()" class="text-dark action-link">
    <font-awesome-icon :icon="icon"></font-awesome-icon>
  </a>

  <teleport to=".body-wrapper">
    <div
      class="modal fade"
      :id="id ? id : 'editModal'"
      tabindex="-1"
      aria-labelledby="editModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel">{{ modalTitle }}</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <component
              :is="formComponent"
              @submitForm="submitUpdate"
              ref="formComponent"
              :defaultObject="defaultObject"
            ></component>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import config from "/vue.config.js";
import axios from "axios";
import { mapMutations } from "vuex";
import { Modal } from "bootstrap";

export default {
  props: [
    "id",
    "icon",
    "modalTitle",
    "formComponent",
    "apiActionUrl",
    "successMessage",
    "redirect",
    "reloadOnSuccess",
    "defaultObject"
  ],
  emits: ["updateSuccess"],
  data() {
    return {
      editModal: null,
    };
  },
  methods: {
    ...mapMutations(["startRequest", "finishRequest", "addToast"]),
    showEditModal() {
      this.$refs.formComponent.setDefaultObject(this.defaultObject);
      this.editModal = new Modal(document.getElementById(this.id ? this.id : 'editModal'));
      this.editModal.show();
    },
    submitUpdate(player) {
      this.editModal.hide();
      this.startRequest();
      axios
        .put(config.pluginOptions.apiBaseUrl + this.apiActionUrl, player)
        .then((response) => {
          if (response.status === 200) {
            this.addToast({
              message: this.successMessage,
            });

            this.$refs.formComponent.resetForm();
            if(this.reloadOnSuccess) {
              this.$router.go();
            } else if(this.redirect) {
              this.$router.push(this.redirect);
            } else {
              this.$emit("updateSuccess");
            }
            this.finishRequest();
          }
        })
        .catch((error) => {
          this.addToast({ message: error.message, error: true });
          this.finishRequest();
        });
    },
  },
};
</script>