<template>
  <div class="mb-4 d-flex justify-content-between align-items-center">
    <h1>
      {{ season.title }}
      <span v-if="season.title_short">- {{ season.title_short }}</span>
    </h1>
    <div>
      <edit-modal
        icon="edit"
        modalTitle="Saison bearbeiten"
        formComponent="season-form"
        :apiActionUrl="'/seasons/' + season.id"
        successMessage="Die Saison wurde erfolgreich aktualisiert!"
        :defaultObject="season"
        @updateSuccess="loadSeason"
      >
      </edit-modal>
      <delete-modal
        icon="trash-alt"
        modalTitle="Saison löschen?"
        :apiActionUrl="'/seasons/' + season.id"
        successMessage="Die Saison wurde erfolgreich gelöscht!"
        redirect="/"
      >
        <p>
          Soll die Saison
          <strong>
            {{ season.title }}
            <span v-if="season.title_short">- {{ season.title_short }}</span>
          </strong>
          wirklich gelöscht werden?
        </p>
        <p>
          Alle dazugehörigen Spiele werden ebenfalls gelöscht. Die Daten können
          nicht wiederhergestellt werden!
        </p>
      </delete-modal>
    </div>
  </div>

  <div class="row">
    <div class="col-12 mt-3 mb-5">
      <season-standings
        :seasonId="seasonId"
        :games_count="season.games_count"
      ></season-standings>
    </div>
    <div class="col-12">
      <game-list :seasonId="seasonId"></game-list>
    </div>
  </div>
</template>

<script>
import config from "/vue.config.js";
import axios from "axios";
import { mapMutations } from "vuex";

import GameList from "../games/GameList.vue";
import SeasonStandings from "../standings/SeasonStandings.vue";
import DeleteModal from "../ui/modals/DeleteModal.vue";
import EditModal from "../ui/modals/EditModal.vue";

export default {
  components: {
    GameList,
    SeasonStandings,
    DeleteModal,
    EditModal,
  },
  props: ["seasonId"],
  data() {
    return {
      season: [],
    };
  },
  methods: {
    ...mapMutations(["startRequest", "finishRequest", "addToast"]),
    loadSeason() {
      this.startRequest();
      axios
        .get(config.pluginOptions.apiBaseUrl + "/seasons/" + this.seasonId)
        .then((response) => {
          if (response.data) {
            this.season = response.data.results;
            this.finishRequest();
          }
        });
    },
  },
  mounted() {
    this.loadSeason();
  },
};
</script>