<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="container">
      <router-link class="navbar-brand" to="/">Seitenball</router-link>
      <div class="d-flex justify-content-end">
        <ul class="navbar-nav flex-row">
          <li class="nav-item">
            <router-link class="nav-link p-2" to="/">Saisons</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link p-2" to="/players">Spieler</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {};
</script>

<style scoped>
</style>