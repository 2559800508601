<template>
  <div
    :id="id"
    class="toast hide align-items-center"
    :class="{ 'text-white bg-danger': toast.error }"
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
  >
    <div class="d-flex">
      <div class="toast-body">{{ toast.message }}</div>
      <button
        type="button"
        class="btn-close me-2 m-auto"
        :class="{ 'btn-close-white': toast.error }"
        data-bs-dismiss="toast"
        aria-label="Close"
      ></button>
    </div>
  </div>
</template>


<script>
import { Toast } from "bootstrap";

export default {
  props: ["id", "toast"],
  mounted() {
    const toastElem = new Toast(document.getElementById(this.id));
    toastElem.show();
  },
};
</script>