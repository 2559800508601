<template>
  <form ref="formElement" class="row g-3" @submit.prevent="submitPlayer()">
    <div class="col-md-6">
      <label for="playerName" class="form-label">Spielername</label>
      <input
        type="text"
        class="form-control"
        id="playerName"
        placeholder="Name des Spielers"
        v-model="player.player_name"
        required
      />
    </div>

    <div class="col-md-6">
      <label for="clubName" class="form-label">Vereinsname</label>
      <input
        type="text"
        class="form-control"
        id="clubName"
        placeholder="Name des Vereins"
        v-model="player.club_name"
      />
    </div>

    <div class="col-12">
      <label for="description" class="form-label">Beschreibung</label>
      <textarea
        class="form-control"
        id="description"
        placeholder="Detaillierte Beschreibung"
        rows="4"
        v-model="player.description"
      />
    </div>

    <div class="col-lg-6">
      <div>
        <label for="logoImage" class="form-label">Vereinslogo</label>
        <input
          type="file"
          accept="image/*"
          id="logoImage"
          data-property="logo_image"
          class="form-control"
          v-if="!player.logo_image"
          @change="onChange"
        />
      </div>

      <div class="img-preview" v-if="player.logo_image">
        <button class="btn" @click="setImageValue('logo_image', '')">
          <font-awesome-icon icon="trash-alt"></font-awesome-icon>
        </button>
        <img :src="'data:image;base64,' + player.logo_image" height="100" />
      </div>
    </div>

    <div class="col-lg-6">
      <div>
        <label for="playerImage" class="form-label">Spielerfoto</label>
        <input
          type="file"
          accept="image/*"
          id="playerImage"
          data-property="player_image"
          class="form-control"
          v-if="!player.player_image"
          @change="onChange"
        />
      </div>

      <div class="img-preview" v-if="player.player_image">
        <button class="btn" @click="setImageValue('player_image', '')">
          <font-awesome-icon icon="trash-alt"></font-awesome-icon>
        </button>
        <img :src="'data:image;base64,' + player.player_image" height="100" />
      </div>
    </div>

    <div class="col-12">
      <button type="submit" class="btn btn-primary">Absenden</button>
    </div>
  </form>
</template>

<script>
export default {
  props: ["defaultObject"],
  data() {
    return {
      player: {
        player_name: "",
        club_name: "",
        description: "",
        logo_image: "",
        player_image: "",
      },
    };
  },
  computed: {
    parsedPlayer() {
      return {
        player_name: this.player.player_name,
        club_name: this.player.club_name,
        description: this.player.description,
        logo_image: this.player.logo_image,
        player_image: this.player.player_image,
      };
    },
  },
  mounted() {
    this.setDefaultObject(this.defaultObject);
  },
  methods: {
    setDefaultObject(defaultPlayer) {
      if (defaultPlayer) {
        this.player.player_name = defaultPlayer ? defaultPlayer.player_name : "";
        this.player.club_name = defaultPlayer ? defaultPlayer.club_name : "";
        this.player.description = defaultPlayer ? defaultPlayer.description : "";
        this.player.logo_image = defaultPlayer ? defaultPlayer.logo_image : "";
        this.player.player_image = defaultPlayer ? defaultPlayer.player_image : "";
      }
    },
    onChange(e) {
      const file = e.target.files[0];

      if (file instanceof File) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (test) => {
          let encoded = test.target.result
            .toString()
            .replace(/^data:(.*,)?/, "");
          if (encoded.length % 4 > 0) {
            encoded += "=".repeat(4 - (encoded.length % 4));
          }

          this.setImageValue(
            e.target.attributes["data-property"].value,
            encoded
          );
        };
      } else {
        this.setImageValue(e.target.attributes["data-property"].value, "");
      }
    },
    setImageValue(propertyName, value) {
      switch (propertyName) {
        case "player_image":
          this.player.player_image = value;
          break;
        case "logo_image":
          this.player.logo_image = value;
          break;
      }
    },
    submitPlayer() {
      this.$emit("submitForm", this.parsedPlayer);
    },
    resetForm() {
      this.$refs.formElement.reset();
    },
  },
};
</script>