<template>
  <div :class="playerId" class="player d-flex align-items-center flex-column flex-lg-row">
    <div class="img-wrapper-small" v-if="logoPosition === 'left'">
      <icon-fallback icon="shield-alt" cssClass="order-0 text-dark" :image="logos[player.id]" />
    </div>

    <div class="text-center player-name" :class="{ 'order-1 order-lg-0': logoPosition === 'right'}">
      <span class="player-club-name">{{ player.club_name }}</span>
      <br/>
      <span>({{ player.player_name }})</span>
    </div>

    <div class="img-wrapper-small" v-if="logoPosition === 'right'">
      <icon-fallback icon="shield-alt" cssClass="order-0 text-dark" :image="logos[player.id]" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["player", "playerId", "logos", "logoPosition"],
};
</script>

<style lang="scss" scoped>
.player {
  &:nth-child(2) {
    margin-top: 1rem;
  }

  .player-name {
    flex: 1;
  }
  .player-club-name {
    font-weight: 700;
    font-size: 1.5rem;
    @include media-breakpoint-down(md) {
      font-size: 1.25rem;
    }
    @include media-breakpoint-down(sm) {
      font-size: 1rem;
    }
  }
}
</style>
