module.exports = {
    pluginOptions: {
        apiBaseUrl: process.env.VUE_APP_API_URL
    },
    css: {
        loaderOptions: {
            scss: {
                additionalData: `
                    @import '~/node_modules/bootstrap/scss/bootstrap';
                    @import '~/node_modules/bootstrap/scss/_functions.scss';
                    @import '~/node_modules/bootstrap/scss/_variables.scss';
                    @import '~/node_modules/bootstrap/scss/_mixins.scss';
                    @import "~@/variables.scss";`
            },
        }
    }
}