<template>
  <div class="body-wrapper">
    <the-header></the-header>

    <div class="container mt-3" :class="{'d-none': activeRequests > 0}">
      <router-view></router-view>
    </div>

    <div v-if="activeRequests > 0" id="loader" class="position-absolute w-100 h-100 d-flex align-items-center justify-content-center">
      <font-awesome-icon icon="volleyball-ball" size="10x" :spin="true"></font-awesome-icon>
    </div>

    <div class="toast-container position-fixed bottom-0 end-0 p-3" style="z-index: 11" id="toast-container">
      <toast-message :id="index" :toast="toast" v-for="(toast, index) in toasts" :key="index"></toast-message>
    </div>
  </div>
</template>

<script>
import TheHeader from './components/ui/TheHeader.vue'
import ToastMessage from './components/ui/ToastMessage.vue';

export default {
  components: {
    TheHeader, ToastMessage
  },
  computed: {
    toasts () {
      return this.$store.state.toasts;
    },
    activeRequests () {
      return this.$store.state.activeRequests;
    }
  }
}
</script>

<style lang="scss" scoped>
  #loader {
    background-color: rgba($white, .75);
    top: 0;
    left: 0;
  }
</style>