<template>
  <table
    class="table table-borderless"
    :class="{ 'table-striped': darkTable, 'my-3 my-md-0': !darkTable }"
  >
    <thead :class="{ 'table-dark': darkTable }">
      <tr>
        <th v-if="showName">Platz</th>
        <th v-if="showName">Name</th>
        <th>G <sup>1)</sup></th>
        <th>S <sup>2)</sup></th>
        <th>N <sup>3)</sup></th>
        <th>P <sup>4)</sup></th>
        <th>PE <sup>5)</sup></th>
        <th>PZ <sup>6)</sup></th>
        <th>Prozent</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(standingItem, index) in standings" :key="index">
        <td v-if="showName" class="font-weight-bold">{{ index + 1 }}.</td>
        <td v-if="showName">
          <span>{{ standingItem.player.club_name }}</span>
          <span v-if="standingItem.player.player_name">
            ({{ standingItem.player.player_name }})</span
          >
        </td>
        <td>{{ standingItem.games_played }}</td>
        <td>{{ standingItem.games_won }}</td>
        <td>{{ standingItem.games_lost }}</td>
        <td>{{ standingItem.points_possible }}</td>
        <td>{{ standingItem.points_scored }}</td>
        <td>{{ standingItem.points_conceded }}</td>
        <td class="font-weight-bold">
          {{ parseFloat(standingItem.percentage).toFixed(2) }}% / {{ parseFloat(standingItem.percentage2).toFixed(2) }}%
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: ["standings", "showName", "darkTable"],
};
</script>

<style lang="scss" scoped>
table {
  thead {
    tr {
      white-space: nowrap;
    }
  }
  tbody {
    tr {
      td {
        span {
          white-space: nowrap;
        }
      }
    }
  }
  &.table-dark td td {
    &:nth-of-type(odd),
    &:nth-of-type(odd) td {
      background-color: $lightgrey;
      box-shadow: none;
    }
  }
}
</style>