<template>
  <form ref="formElement" class="row g-3" @submit.prevent="submitGame()">
    <div class="col-12">
      <label for="title" class="form-label">Titel</label>
      <input
        type="text"
        class="form-control"
        id="title"
        placeholder="Titel"
        v-model="game.title"
      />
    </div>

    <div class="col-md-6">
      <label for="number" class="form-label">Spiel-Nr.</label>
      <input
        type="text"
        class="form-control"
        id="number"
        :placeholder="parameters && parameters.nextNumber ? parameters.nextNumber : 'Nummer'"
        v-model="game.number"
      />
    </div>

    <div class="col-md-6">
      <label for="date" class="form-label">Datum</label>
      <input
        type="date"
        class="form-control"
        id="date"
        v-model="game.date"
      />
    </div>

    <div class="col-12">
      <label for="note" class="form-label">Notiz</label>
      <input
        type="text"
        class="form-control"
        id="note"
        placeholder="Notiz"
        v-model="game.note"
      />
    </div>

    <div class="col-md-6" v-if="parameters && parameters.players">
      <label for="player_0" class="form-label">Spieler 1</label>
      <select
        class="form-control"
        id="player_0"
        v-model="game.player_0"
        required>
        <option v-for="player in parameters.players" :key="player.id" :value="player.id">{{ player.club_name }} <span v-if="player.player_name">({{ player.player_name }})</span></option>
      </select>
    </div>

    <div class="col-md-6" v-if="parameters && parameters.players">
      <label for="player_1" class="form-label">Spieler 2</label>
      <select
        class="form-control"
        id="player_1"
        v-model="game.player_1"
        required>
        <option v-for="player in parameters.players" :key="player.id" :value="player.id">{{ player.club_name }} <span v-if="player.player_name">({{ player.player_name }})</span></option>
      </select>
    </div>

    <div class="col-md-6" v-if="parameters && parameters.players">
      <label for="player_0_b" class="form-label">Spieler 1 B</label>
      <select
        class="form-control"
        id="player_0_b"
        v-model="game.player_0_b">
        <option v-for="player in parameters.players" :key="player.id" :value="player.id">{{ player.club_name }} <span v-if="player.player_name">({{ player.player_name }})</span></option>
      </select>
    </div>

    <div class="col-md-6" v-if="parameters && parameters.players">
      <label for="player_1_b" class="form-label">Spieler 2 B</label>
      <select
        class="form-control"
        id="player_1_b"
        v-model="game.player_1_b">
        <option v-for="player in parameters.players" :key="player.id" :value="player.id">{{ player.club_name }} <span v-if="player.player_name">({{ player.player_name }})</span></option>
      </select>
    </div>

    <div v-if="game.player_0 && game.player_1 && game.player_0 === game.player_1" class="col-12 mt-1 text-center">
      Bitte wähle zwei unterschiedliche Spieler aus!
    </div>

    <div class="col-md-6">
      <label for="points_0" class="form-label">Punkte {{ game.player_0 && game.player_0.club_name ? game.player_0.club_name : 'Spieler 1'}}</label>
      <input
        type="number"
        class="form-control"
        id="points_0"
        v-model="game.points_0"
        max="10"
        required
      />
    </div>

    <div class="col-md-6">
      <label for="points_1" class="form-label">Punkte {{ game.player_1 && game.player_1.club_name ? game.player_1.club_name : 'Spieler 2'}}</label>
      <input
        type="number"
        class="form-control"
        id="points_1"
        v-model="game.points_1"
        max="10"
        required
      />
    </div>

    <div class="col-12">
      <input
        type="checkbox"
        id="exclude_from_standings"
        v-model="game.exclude_from_standings"
      />
      <label for="exclude_from_standings">&nbsp;Nicht in Wertung mitaufnehmen</label>
    </div>

    <div class="col-12">
      <button type="submit" class="btn btn-primary" :disabled="!game.player_0 || !game.player_1 || game.player_0 === game.player_1">Absenden</button>
    </div>
  </form>
</template>

<script>
export default {
  props: ["defaultObject","parameters"],
  data() {
    return {
      game: {
        season: null,
        title: "",
        number: null,
        note: "",
        exclude_from_standings: false,
        date: new Date().toISOString().slice(0, 10),
        points_0: 0,
        player_0: null,
        player_0_b: null,
        points_1: 0,
        player_1: null,
        player_1_b: null
      },
    };
  },
  computed: {
    parsedGame() {
      return {
        season: this.game.season,
        title: this.game.title,
        number: this.game.number,
        note: this.game.note,
        exclude_from_standings: this.game.exclude_from_standings,
        date: this.game.date,
        points_0: this.game.points_0,
        player_0: this.game.player_0.id ? this.game.player_0.id : this.game.player_0,
        player_0_b: this.game.player_0_b && this.game.player_0_b.id ? this.game.player_0_b.id : this.game.player_0_b,
        points_1: this.game.points_1,
        player_1: this.game.player_1.id ? this.game.player_1.id : this.game.player_1,
        player_1_b: this.game.player_1_b && this.game.player_1_b.id ? this.game.player_1_b.id : this.game.player_1_b,
      };
    },
  },
  mounted() {
    this.setDefaultObject(this.defaultObject);
    if(this.parameters && this.parameters.season) {
      this.game.season = this.parameters.season;
    }
  },
  methods: {
    setDefaultObject(defaultGame) {
      if (defaultGame) {
        this.game.title = defaultGame ? defaultGame.title : "";
        this.game.number = defaultGame ? defaultGame.number : null;
        this.game.note = defaultGame ? defaultGame.note : "";
        this.game.exclude_from_standings = defaultGame ? defaultGame.exclude_from_standings : false;
        this.game.date = defaultGame ? defaultGame.date : "2022-10-10";
        this.game.points_0 = defaultGame ? defaultGame.points_0 : 0;
        this.game.player_0 = defaultGame ? defaultGame.player_0 : null;
        this.game.points_1 = defaultGame ? defaultGame.points_1 : 0;
        this.game.player_1 = defaultGame ? defaultGame.player_1 : null;
      }
    },
    submitGame() {
      this.$emit("submitForm", this.parsedGame);
    },
    resetForm() {
      this.$refs.formElement.reset();
    },
  },
};
</script>
